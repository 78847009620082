import React from 'react';
import { WorkContainer, MainContent, Image, Text, ContentWrapper, Title } from './styles';
import { useInView } from 'react-intersection-observer';
import { Button } from '../Button';

const Works = ({ id, title, photo, onClick }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <MainContent id={id}>
      <ContentWrapper>
        <WorkContainer ref={ref} className={inView ? 'visible' : ''}>
          <Image src={photo} alt="Placeholder" />
          <div>
            <Title>
              {title}
            </Title>
            <Text>
              Clique no botão abaixo para acessar.
            </Text>
            <Button title={'Ver mais!'} onClick={onClick} />
          </div>
        </WorkContainer>
      </ContentWrapper>
    </MainContent>
  )
}

export { Works }; 
