import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.main`
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 3.5em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column; 
        max-width: 90%;
        margin-top: 50px;
    }
`;

export const ButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8em;
    margin-bottom: 7em;
    @media screen and (max-width: 768px) {
        margin-top: 3em;
    }
`;

export const Title = styled.h1`
    font-family: 'Sora';
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    width: 620px;
    margin-top: 240px;
    margin-bottom: 20px;
    line-height: 60px;

    animation: ${fadeIn} 1.5s forwards;

    color: #fff;

    @media screen and (max-width: 768px) {
        font-size: 48px;
        width: 90%;
        margin: 0 auto;
        margin-top: 120px;
        text-align: center;
    }
`;

export const TextContent = styled.p`
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    width: 720px;

    animation: ${fadeIn} 2.5s forwards;

    color: #fff;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        width: 90%;
        text-align: center;
        margin: 0 auto;
    }
`;