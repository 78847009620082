import styled, {keyframes} from 'styled-components';

import icon from '../../assets/icons/whatsapp.ico'


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  width: 70px;
  height: 70px;
  background-image: url(${icon});
  background-size: cover;
  background-position: center;
  position: fixed;
  bottom: 38px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  opacity: 0; /* Start with the element hidden */
  animation: ${fadeIn} 3s forwards;
  animation-delay: 3s; /* Add a delay of 3 seconds */
  transition: opacity 3s ease-in-out 3s; /* Transition with delay */
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 38px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  opacity: 0;
  transition: opacity 3s ease-in-out 3s; /* Transition with delay */

  &.visible {
    opacity: 1;
  }
`;