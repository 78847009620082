import React, { useState } from 'react';
import logoimg from '../../assets/ecslogo.png';
import { useNavigate } from "react-router-dom";

import { HeaderWrapper, Nav, Logo, Text, HamburgerMenu, MobileNav } from './styles';

const Header = () => {
  const scrollToElement = () => {
    const element = document.getElementById('footer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleClickProjects = () => {
    navigate('/projects');
    setIsMobileMenuOpen(false);
  };

  const handleClickContact = () => {
    navigate('/contact');
    setIsMobileMenuOpen(false);
  };

  const handleClickHome = () => {
    navigate('/');
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <HeaderWrapper>
      <Logo src={logoimg} alt="Logo da Empresa" onClick={handleClickHome} />
      <HamburgerMenu onClick={toggleMobileMenu}>
        &#9776; {/* Código do menu hambúrguer */}
      </HamburgerMenu>
      <Nav isMobileMenuOpen={isMobileMenuOpen}>
        <ul>
          <li><Text onClick={handleClickHome}>Início</Text></li>
          <li><Text onClick={handleClickProjects}>Projetos</Text></li>
          <li><Text onClick={handleClickContact}>Contato</Text></li>
        </ul>
      </Nav>
      {isMobileMenuOpen && (
        <MobileNav>
          <ul>
            <li><Text onClick={handleClickHome}>Início</Text></li>
            <li><Text onClick={handleClickProjects}>Projetos</Text></li>
            <li><Text onClick={handleClickContact}>Contato</Text></li>
          </ul>
        </MobileNav>
      )}
    </HeaderWrapper>
  );
}

export { Header };