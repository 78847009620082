import React from 'react';
import { WorkContainer, MainContent, Image, Text, ContentWrapper, Title } from './styles';
import { useInView } from 'react-intersection-observer';
import { WhatsAppButton } from '../../components/WhatsAppButton';
import logo from '../../assets/ecslogo.png'

import { Background } from '../../components/Background';

const Contact = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (<>
    <Background />
    <MainContent>
      <ContentWrapper>
        <WorkContainer ref={ref} className={inView ? 'visible' : ''}>
          <Image src={logo} alt="Placeholder" />
          <div>
            <Title>
              Sua casa como você<br></br> sempre sonhou
            </Title>
            <Text>
              Entre em contato conosco pelo WhatsApp!
            </Text>
            <WhatsAppButton />
          </div>
        </WorkContainer>
      </ContentWrapper>
    </MainContent>
  </>
  )
}

export { Contact }; 
