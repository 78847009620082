import React from 'react'

import { ButtonContainer } from './styles';

const GoBack = ({title, onClick}) => {
  return (
    <ButtonContainer onClick={onClick}>
        <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="4"
        transform="rotate(180)" // Adiciona a rotação aqui
        >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
        ></path>
        </svg>
      <p>Voltar</p>
    </ButtonContainer>
  )
}

export { GoBack }