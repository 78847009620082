import React, { useState, useEffect } from 'react';
import { Image, FullScreenContainer, FullScreenImage, CloseButton, ZoomInButton, ZoomOutButton, NextButton, PrevButton, Container } from './styles';

import { Loader } from '../Loader';

const importImages = (folder) => {
  switch (folder) {
    case 'cozinha':
      return importAll(require.context('../../assets/Moveis/Cozinha', false, /\.(png|jpe?g|svg)$/));
    case 'quarto':
      return importAll(require.context('../../assets/Moveis/Quarto', false, /\.(png|jpe?g|svg)$/));
    case 'sala':
      return importAll(require.context('../../assets/Moveis/Sala', false, /\.(png|jpe?g|svg)$/));
    case 'banheiro':
      return importAll(require.context('../../assets/Moveis/Banheiro', false, /\.(png|jpe?g|svg)$/));
    case 'gourmet':
      return importAll(require.context('../../assets/Moveis/Gourmet', false, /\.(png|jpe?g|svg)$/));
    case 'lavanderia':
      return importAll(require.context('../../assets/Moveis/Lavanderia', false, /\.(png|jpe?g|svg)$/));
    case 'escritorio':
      return importAll(require.context('../../assets/Moveis/Escritorio', false, /\.(png|jpe?g|svg)$/));
    case 'outros':
      return importAll(require.context('../../assets/Moveis/Outros', false, /\.(png|jpe?g|svg)$/));
    default:
      return [];
  }
};

const importAll = (requireContext) => {
  // Ordena os arquivos por nome em ordem decrescente
  return requireContext.keys()
    .sort((a, b) => b.localeCompare(a)) // Ordenação decrescente por nome
    .map(requireContext);
};

const ImageGallery = ({ imagesFolder }) => {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [closingFullScreen, setClosingFullScreen] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento das imagens
  const images = importImages(imagesFolder);

  useEffect(() => {
    setLoading(true); // Quando o componente for montado, definimos o estado de loading como true
    const imageLoadPromises = images.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = image;
      });
    });

    Promise.all(imageLoadPromises)
      .then(() => {
        setLoading(false); // Quando todas as imagens estiverem carregadas, definimos o estado de loading como false
      })
      .catch(error => {
        console.error('Erro ao carregar imagem:', error);
        setLoading(false); // Em caso de erro, definimos o estado de loading como false para garantir que o loader desapareça
      });
  }, [imagesFolder]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setFullScreenImage(images[index]);
    setZoomLevel(1); 
    setPosition({ x: 0, y: 0 }); 
    setClosingFullScreen(false); 
  }

  const handleCloseFullScreen = () => {
    setClosingFullScreen(true); 
    setTimeout(() => {
      setFullScreenImage(null);
    }, 300);
  }

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); 
  }

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); 
  }

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastPosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - lastPosition.x;
    const dy = e.clientY - lastPosition.y;
    setPosition((prevPos) => ({ x: prevPos.x + dx, y: prevPos.y + dy }));
    setLastPosition({ x: e.clientX, y: e.clientY });
  }

  const handleMouseUp = () => {
    setIsDragging(false);
  }

  const handleTouchStart = (e) => {
    setIsDragging(true);
    const touch = e.touches[0];
    setLastPosition({ x: touch.clientX, y: touch.clientY });
    e.preventDefault();
  }

  const handleDragStart = (e) => {
    e.preventDefault();
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      setFullScreenImage(null);
    } else if (e.key === 'ArrowRight') {
      handleNextImage();
    } else if (e.key === 'ArrowLeft') {
      handlePrevImage();
    }
  }

  const handleNextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
    setFullScreenImage(images[newIndex]);
  }

  const handlePrevImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
    setFullScreenImage(images[newIndex]);
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [currentIndex]);

    return (
      <Container>
        {loading && <Loader />}
        {images.map((image, index) => (
          <Image key={index} src={image} alt={`Image ${index + 1}`} onClick={() => handleImageClick(index)} />
        ))}
        {fullScreenImage && (
          <FullScreenContainer
          closing={closingFullScreen}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          >
            <FullScreenImage
              src={fullScreenImage}
              alt="Full Screen"
              zoomLevel={zoomLevel}
              position={position}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onDragStart={handleDragStart}
              onTouchStart={handleTouchStart}
              />
            <CloseButton onClick={handleCloseFullScreen}>X</CloseButton>
            <ZoomInButton onClick={handleZoomIn}>+</ZoomInButton>
            <ZoomOutButton onClick={handleZoomOut}>-</ZoomOutButton>
            <NextButton onClick={handleNextImage}>{'>'}</NextButton>
            <PrevButton onClick={handlePrevImage}>{'<'}</PrevButton>
          </FullScreenContainer>
        )}
      </Container>
    );
  };

export { ImageGallery };
