import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  margin-bottom: 20px;
  line-height: 60px;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 15px;
  }
`;

export const WorkContainer = styled.div`
  margin-top: 18em;
  margin-bottom: 15em;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(20px);

  &.visible {
    opacity: 1;
    transform: translateY(0);
    animation: ${fadeIn} 3s forwards;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  border-radius: 1.5em;
  width: 450px;
  height: 400px;
  margin-left: 3em;
  margin-right: 2em;

  @media screen and (max-width: 768px) {
    width: 40%;
    height: auto;
    margin-left: 1.5em;
    margin-right: 15px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: 'Sora';
  margin-bottom: 20px;
  text-align: left;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
