import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.div`

`;

export const LoadingSpinner = styled.div`

`

export const Image = styled.img`
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
`

export const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${({ closing }) => (closing ? fadeOut : fadeIn)} 0.3s ease; /* Adiciona a animação de entrada ou saída */
`;

export const FullScreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  transform: ${({ zoomLevel, position }) => `scale(${zoomLevel}) translate(${position.x / zoomLevel}px, ${position.y / zoomLevel}px)`};
  transition: transform 0.3s ease;
  cursor: ${({ zoomLevel }) => (zoomLevel > 1 ? 'grab' : 'auto')};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

export const ZoomInButton = styled.button`
display: none; /* Oculta a Sidebar por padrão */

  @media (min-width: 768px) {
    display: block; /* Exibe a Sidebar em dispositivos com largura mínima de 768px */
    position: absolute;
    bottom: 20px;
    right: 60px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none; /* Oculta a Sidebar em dispositivos com largura máxima de 768px (dispositivos móveis) */
  }
`;

export const ZoomOutButton = styled.button`
  display: none; /* Oculta a Sidebar por padrão */

  @media (min-width: 768px) {
    display: block; /* Exibe a Sidebar em dispositivos com largura mínima de 768px */
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none; /* Oculta a Sidebar em dispositivos com largura máxima de 768px (dispositivos móveis) */
  }
`;


export const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  z-index: 10;
`;

export const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  z-index: 10;
`;