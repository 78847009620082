import styled from 'styled-components';

export const WorksContainer = styled.div`

  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espaçamento entre os componentes Works */
  padding: 20px;
  margin-bottom: 10em;

  @media (max-width: 768px) {
    margin-bottom: 8em;

  }
`;

export const Container = styled.div`
  background: rgb(200, 214, 240);
  background: linear-gradient(0deg, rgba(200, 214, 240, 1) 0%, rgba(221, 233, 255, 1) 63%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden; /* Para esconder a barra de rolagem horizontal */

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%; /* Ajuste para 100% da largura da tela em dispositivos móveis */
  }
`;

export const Sidebar = styled.div`
  display: none; /* Oculta a Sidebar por padrão */

  @media (min-width: 768px) {
    display: block; /* Exibe a Sidebar em dispositivos com largura mínima de 768px */
    position: fixed;
    border-radius: 1em;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    background-color: rgba(97, 138, 75, 0.3);
    padding: 10px;
    padding-top: 15px;
    margin-right: 60px;
  }

  @media (max-width: 768px) {
    display: none; /* Oculta a Sidebar em dispositivos com largura máxima de 768px (dispositivos móveis) */
  }
`;
