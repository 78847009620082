import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { WorksContainer, Container, Sidebar } from './styles';
import { Radio } from '../../components/Radio';
import { Works } from '../../components/Works';
import { WhatsAppIcon } from '../../components/WhatsAppIcon';

import kitchenImage from '../../assets/Banners/kitchen.jpg'
import livingRoomImage from '../../assets/Banners/Sala.jpg'
import bathroomImage from '../../assets/Banners/Banheiro.jpg'
import gourmetImage from '../../assets/Banners/Gourmet.jpg'
import deskImage from '../../assets/Banners/EscritorioBanner.jpg'
import othersImage from '../../assets/Banners/Outros.jpg'
import roomImage from '../../assets/Banners/Quarto.jpg'
import laundryImage from '../../assets/Banners/Lavanderia.jpg'

const Projects = () => {
  const [activeSection, setActiveSection] = useState('');

  const handleScroll = () => {
    const cozinhaTop = document.getElementById('cozinhas').offsetTop;
    const quartoTop = document.getElementById('quartos').offsetTop;
    const salaTop = document.getElementById('salas').offsetTop;
    const deskTop = document.getElementById('escritorios').offsetTop;
    const banheiroTop = document.getElementById('banheiros').offsetTop;
    const gourmetTop = document.getElementById('gourmets').offsetTop;
    const lavanderiaTop = document.getElementById('lavanderias').offsetTop;
    const outrosTop = document.getElementById('outros').offsetTop;
    const scrollPosition = window.scrollY + window.innerHeight / 2;
  
    if (scrollPosition >= cozinhaTop && scrollPosition < quartoTop) {
      setActiveSection('cozinhas');
    } else if (scrollPosition >= quartoTop && scrollPosition < salaTop) {
      setActiveSection('quartos');
    } else if (scrollPosition >= salaTop && scrollPosition < deskTop) {
      setActiveSection('salas');
    } else if (scrollPosition >= deskTop && scrollPosition < banheiroTop) {
      setActiveSection('escritorios');
    } else if (scrollPosition >= banheiroTop && scrollPosition < gourmetTop) {
      setActiveSection('banheiros');
    } else if (scrollPosition >= gourmetTop && scrollPosition < lavanderiaTop) {
      setActiveSection('gourmets');
    } else if (scrollPosition >= lavanderiaTop && scrollPosition < outrosTop) {
      setActiveSection('lavanderias');
    } else if (scrollPosition >= outrosTop) {
      setActiveSection('outros');
    }
  };
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const navigate = useNavigate();

  const handleClickKitchen = () => {
    navigate('/imagesKitchen')
  }
  const handleClickRoom = () => {
    navigate('/imagesRoom')
  }
  const handleClickBathroom = () => {
    navigate('/imagesBathroom')
  }
  const handleClickLivingRoom = () => {
    navigate('/imagesLivingRoom')
  }
  const handleClickGourmet = () => {
    navigate('/imagesGourmet')
  }
  const handleClickOthers = () => {
    navigate('/imagesOthers')
  }
  const handleClickLaundry = () => {
    navigate('/imagesLaundry')
  }
  const handleClickDesk = () => {
    navigate('/imagesDesk')
  }

  return (
    <Container>
      <Sidebar desktopOnly>
        <Radio name="cozinhas" checked={activeSection === 'cozinhas'} />
        <Radio name="quartos" checked={activeSection === 'quartos'} />
        <Radio name="salas" checked={activeSection === 'salas'} />
        <Radio name="escritorios" checked={activeSection === 'escritorios'} />
        <Radio name="banheiros" checked={activeSection === 'banheiros'} />
        <Radio name="gourmets" checked={activeSection === 'gourmets'} />
        <Radio name="lavanderias" checked={activeSection === 'lavanderias'} />
        <Radio name="outros" checked={activeSection === 'outros'} />
      </Sidebar>
      <WorksContainer>
        <Works id="cozinhas" title="Cozinhas!" photo={kitchenImage} onClick={handleClickKitchen}/>
        <Works id="quartos" title="Quartos!" photo={roomImage} onClick={handleClickRoom}/>
        <Works id="salas" title="Salas!" photo={livingRoomImage} onClick={handleClickLivingRoom}/>
        <Works id="escritorios" title="Escritórios!" photo={deskImage} onClick={handleClickDesk}/>
        <Works id="banheiros" title="Banheiros!" photo={bathroomImage} onClick={handleClickBathroom}/>
        <Works id="gourmets" title="Gourmets!" photo={gourmetImage} onClick={handleClickGourmet}/>
        <Works id="lavanderias" title="Lavanderias!" photo={laundryImage} onClick={handleClickLaundry}/>
        <Works id="outros" title="Outros!" photo={othersImage} onClick={handleClickOthers}/>
      </WorksContainer>
      <WhatsAppIcon />
    </Container>
  );
};

export { Projects };
