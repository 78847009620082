import React from 'react';

import videoFile from '../../assets/background.mp4';

import { VideoBackground } from './styles';

const Background = () => {
  return (
    <VideoBackground autoPlay loop muted>
      <source src={videoFile} type="video/mp4" />
      Seu navegador não suporta a tag de vídeo.
    </VideoBackground>
  );
};

export {Background};