import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    margin-top: 10em;
    column-count: 5;
    column-gap: 15px;
    margin-left: auto;
    margin-right: auto;
    /* Alternativa com margin: 0 auto; */
    display: block; /* Garante que o container se comporte como um bloco */
    text-align: center;
    @media screen and (max-width: 768px) {
        width: 90%;
        column-count: 2;
    }
`
export const GoBackContainer = styled.div`
    position: fixed;
    bottom: 38px;
    left: 20px;
    z-index: 999;
`