import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ScrollToTop from "./ScrollToTop";

import { GlobalStyle } from './styles/global';
import { Header } from './components/Header'
import { Footer } from './components/Footer'

import { Home } from './pages/home'
import { Projects } from './pages/projects'
import { Contact } from './pages/contact'
import { ImagesKitchen } from "./pages/ImagesKitchen";
import { ImagesRoom } from "./pages/ImagesRoom";
import { ImagesLivingRoom } from "./pages/ImagesLivingRoom";
import { ImagesBathroom } from "./pages/ImagesBathroom";
import { ImagesGourmet } from "./pages/ImagesGourmet";
import { ImagesLaundry } from "./pages/ImagesLaundry";
import { ImagesOthers } from "./pages/ImagesOthers";
import { ImagesDesk } from "./pages/ImagesDesk";

function App() {
  return (
    <Router>
      <ScrollToTop />
     <GlobalStyle />
    <Header />
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Home />} />
        <Route path="/projects" element={<Projects />}  />
        <Route path="/imagesKitchen" element={<ImagesKitchen />} />
        <Route path="/imagesRoom" element={<ImagesRoom />} />
        <Route path="/imagesLivingRoom" element={<ImagesLivingRoom />} />
        <Route path="/imagesBathroom" element={<ImagesBathroom />} />
        <Route path="/imagesBathroom" element={<ImagesBathroom/>} />
        <Route path="/imagesGourmet" element={<ImagesGourmet />} />
        <Route path="/imagesLaundry" element={<ImagesLaundry />} />
        <Route path="/imagesDesk" element={<ImagesDesk />} />
        <Route path="/imagesOthers" element={<ImagesOthers />} />
        <Route path="/contact" element={<Contact />} />
     </Routes >
     <Footer />
    </Router>
  );
}

export default App;