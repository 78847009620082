import React from 'react';

import {FooterContainer,  LeftSection, TopSection, CompanyName, SmallText, CenterSection, ProjectsTitle, 
        ProjectsList, RightSection, ContactTitle, ContactList, BottomSection, Copyright, Text, Copyright2 } from './styles'

import { useNavigate } from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate();

    const handleClickKitchen = () => {
        navigate('/imagesKitchen')
      }
      const handleClickRoom = () => {
        navigate('/imagesRoom')
      }
      const handleClickBathroom = () => {
        navigate('/imagesBathroom')
      }
      const handleClickLivingRoom = () => {
        navigate('/imagesLivingRoom')
      }
      const handleClickGourmet = () => {
        navigate('/imagesGourmet')
      }
      const handleClickOthers = () => {
        navigate('/imagesOthers')
      }
      const handleClickLaundry = () => {
        navigate('/imagesLaundry')
      }
      const handleClickDesk = () => {
        navigate('/imagesDesk')
      }

      const handleClickOpenWhatsApp = () => {
        window.open('https://wa.me/5511984623846', '_blank', 'noopener,noreferrer');
      }

      const handleClickOpenFerrazDevelopment = () => {
        window.open('https://ferrazdevelopment.com.br', '_blank', 'noopener,noreferrer');
      }

    return (<>
        <FooterContainer id='footer'>
            <TopSection>
                <LeftSection>
                    <CompanyName>ECS Marcenaria</CompanyName>
                    <SmallText>
                    Na Marcenaria ECS, seu sonho é o nosso compromisso. <br />
                    Prezamos pela excelência e qualidade em cada móvel que criamos. <br />
                    Estamos sempre prontos para transformar suas ideias em realidade. <br />
                    Conte conosco para seus próximos projetos. <br />
                    </SmallText>
                </LeftSection>
                <CenterSection>
                    <ProjectsTitle>Projetos</ProjectsTitle>
                    <ProjectsList>
                        <li><Text onClick={handleClickKitchen}>Cozinha</Text></li>
                        <li><Text onClick={handleClickRoom}>Quarto</Text></li>
                        <li><Text onClick={handleClickDesk}>Escritório</Text></li>
                        <li><Text onClick={handleClickBathroom}>Banheiro</Text></li>
                        <li><Text onClick={handleClickGourmet}>Gourmet</Text></li>
                        <li><Text onClick={handleClickLaundry}>Lavanderia</Text></li>
                        <li><Text onClick={handleClickLivingRoom}>Sala</Text></li>
                        <li><Text onClick={handleClickOthers}>Outros</Text></li>
                    </ProjectsList>
                </CenterSection>
                <RightSection>
                    <ContactTitle>Contato</ContactTitle>
                    <ContactList>
                        <li><Text onClick={handleClickOpenWhatsApp}>
                                WhatsApp: +55 (11) 98462-3846 
                            </Text></li>
                    </ContactList>
                </RightSection>
            </TopSection>
            <BottomSection>
                <Copyright>© ECS Marcenaria</Copyright>
                <Copyright2 onClick={handleClickOpenFerrazDevelopment}>Developed by: Ferraz Development</Copyright2>
            </BottomSection>
        </FooterContainer>
    </>
            );
};
    

export { Footer }