import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  margin-bottom: 0px;
  text-align: left;
  color: #282828;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

export const WorkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10em;
  justify-content: center;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(20px);

  &.visible {
    animation: ${fadeIn} 3s forwards;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  border-radius: 1.5em;
  width: 650px;
  height: 600px;
  margin-left: 20px;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    width: 50%;
    height: auto;
    margin-right: 15px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: 'Sora';
  margin-bottom: 10px;
  font-weight: 400;
  text-align: left;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
