import styled from 'styled-components';

export const ButtonContainer = styled.button`
  font-family: 'Sora';
  font-size: 16px;
  position: relative;
  margin: auto;
  cursor: pointer;
  padding: 1em 2.5em 1em 2.5em;
  border: none;
  border-radius: 6em;
  background: #618a4b;
  transition: all 0.1s linear;
  box-shadow: 0 0.4em 1em rgba(0, 0, 0, 0.1);

  &:active {
    transform: scale(0.95);
  }

  span {
    color: #fff;
  }

  .border {
    display: none; /* Oculta a borda por padrão */
    position: absolute;
    border: 0.15em solid #618a4b;
    transition: all 0.3s 0.08s linear;
    top: 50%;
    left: 50%;
    width: 9em;
    height: 3em;
    transform: translate(-50%, -50%);
    border-radius: 6em; /* Adiciona borda arredondada */
  }

  &:hover .border {
    display: block; /* Exibe a borda ao passar o mouse */
    width: 9.9em;
    height: 3.7em;
  }

  @media (max-width: 768px) {
    padding: 0.8em 2.2em; /* Ajusta o padding para dispositivos móveis */
    font-size: 14px; /* Ajusta o tamanho da fonte para dispositivos móveis */
  }
`;

export const FullRounded = styled.div`
  border-radius: 2em;
`;
