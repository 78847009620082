
import styled from 'styled-components';

export const RadioContainer = styled.div`
  margin: 0 auto;
  max-width: 300px;
  color: white;
`;

export const RadioWrapper = styled.div`
  margin-bottom: 20px;
`;

export const RadioButton = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }

  input[type='radio'] {
    display: none;
  }
`;

export const RadioCheckmark = styled.span`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 2px solid #333;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #333;
    transition: all 0.2s ease-in-out;
  }
`;

export const RadioLabel = styled.span`
  font-family: 'Sora';
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

export const Input = styled.input`
  &:checked ~ ${RadioCheckmark}:before {
    transform: translate(-50%, -50%) scale(1);
  }
`;
