import styled from 'styled-components';

export const ButtonContainer = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;

  --primary-color: #fff;
  --hovered-color: #618a4b;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 25px;
  gap: 0.5rem;
  align-items: center;

  p {
    margin: 0;
    position: relative;
    font-size: 25px;
    color: var(--primary-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  }

  &::after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    bottom: -7px;
    background: var(--hovered-color);
    height: 2px;
    transition: 0.3s ease-out;
  }

  p::before {
    position: absolute;
    content: "Voltar";
    width: 0%;
    inset: 0;
    color: var(--hovered-color);
    overflow: hidden;
    transition: 0.3s ease-out;
  }

  &:hover::after {
    width: 100%;
  }

  &:hover p::before {
    width: 100%;
  }

  /* Removemos a transformação no estado de hover */
  &:hover svg {
    transform: rotate (180deg);
    color: var(--hovered-color);
  }

  svg {
    color: var(--primary-color);
    transition: color 0.2s;
    position: relative;
    width: 20px;
  }
`;
