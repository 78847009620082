import styled from 'styled-components';

export const VideoBackground = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
`;