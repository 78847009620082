import React from 'react'

import { ButtonContainer, FullRounded } from './styles';

const Button = ({title, onClick}) => {
  return (
    <ButtonContainer onClick={onClick}>
      <span>{title}</span>
      <FullRounded className="border full-rounded" />
    </ButtonContainer>
  )
}

export { Button }