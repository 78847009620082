import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button';
import { Background } from '../../components/Background';
import { WhatsAppIcon } from '../../components/WhatsAppIcon';

import { Container, Title, TextContent, ButtonBox } from './styles';

const Home = () => {

    const navigate = useNavigate();

    const handleClickProjects = () => {
        navigate('/projects')
    }

    return (<>
        <Background />
          <Container>
              <div>
                <Title>
                    Bem-vindo à Marcenaria ECS! <br />
                </Title>
                <TextContent>
                  Transformamos sonhos em realidade! <br />
                  Somos especialistas em marcenaria em Jundiaí e região.<br />
                  <br />
                  Descubra nossos projetos em "Ver mais!" <br />
                  e deixe-nos fazer parte do seu próximo projeto.
                </TextContent>
              </div>       
          </Container>
          <WhatsAppIcon />
          <ButtonBox>
            <Button title={'Ver mais!'} onClick={handleClickProjects}></Button>
          </ButtonBox>       
      </>
    );
}

export { Home }
