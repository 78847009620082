import React from 'react';
import { scroller } from 'react-scroll';
import { Input, RadioLabel, RadioContainer, RadioWrapper, RadioButton, RadioCheckmark } from './styles';

function toUpperCaseString(str) {
  return str.toUpperCase();
}

const Radio = ({ name, checked }) => {
  const handleChange = () => {
    scroller.scrollTo(name, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <RadioContainer>
      <RadioWrapper>
        <RadioButton>
          <Input name="radio-group" type="radio" checked={checked} onChange={handleChange} />
          <RadioCheckmark />
          <RadioLabel>{toUpperCaseString(name)}</RadioLabel>
        </RadioButton>
      </RadioWrapper>
    </RadioContainer>
  );
};

export { Radio };
