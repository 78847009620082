import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageGallery } from '../../components/ImageGallery';
import { GoBack } from '../../components/GoBack';
import { WhatsAppIcon } from '../../components/WhatsAppIcon';
import { Container, GoBackContainer} from './styles'; // Importando os estilos

const ImagesKitchen = () => {
    const navigate = useNavigate();

    const handleClickGoBack = () => {
      navigate('/projects');
    };

    return (
        <Container>
            <GoBackContainer>
                <GoBack onClick={handleClickGoBack} />
            </GoBackContainer>

                <ImageGallery imagesFolder={'cozinha'} />

            <WhatsAppIcon />
        </Container>
    );
};

export { ImagesKitchen };
