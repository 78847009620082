import React, { useEffect, useState } from 'react';
import { IconWrapper, IconContainer } from './styles';

const WhatsAppIcon = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer);
  }, []);

  const handleClickOpenWhatsApp = () => {
    window.open('https://wa.me/5511984623846', '_blank', 'noopener,noreferrer');
  };

  return (
    <IconWrapper className={visible ? 'visible' : ''} onClick={handleClickOpenWhatsApp}>
      <IconContainer />
    </IconWrapper>
  );
};

export { WhatsAppIcon };